/*
 * Custom Fonts
 */
@font-face {
  font-family: 'FoundersGrotesk';
  src: url('../fonts/founders-grotesk-web-light.woff2') format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'FoundersGroteskRegular';
  src: url('../fonts/founders-grotesk-web-regular.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}



/*
 * Core styles required for correct layout
 */
.goodmancube {
  position: relative;
  box-sizing: content-box; /*Required to add padding config.addPaddingForCopy */
}
.goodmancube-wrapper {
  margin: 0 auto;
  max-width: 800px;
  max-height: 800px;
  width: 100%;

}
/* Canvas container will always be a square */
.goodmancube-canvascontainer {
  width: 100%;
  position: relative;
}
.goodmancube-canvascontainer:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}
.goodmancube-canvascontainer canvas {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* Copy container will be positioned by Textmanager.ts */
.goodmancube-copycontainer {
  position: absolute;
  height: 200px;
  pointer-events: none;
  left: 50%;
  transform: translateX(-50%);
  overflow: visible;
}
.goodmancube-copy {
  position: absolute;
  top: 0;
  width: 100%;
}

.goodmancube-copy--entering-start {
  transition: all 0.4s cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0;
}
.goodmancube-copy--entering {
  opacity: 1;
}

.goodmancube-copy--exiting-start {
  transition: all 0.1s ease-out;
  opacity: 1;
}

.goodmancube-copy--exiting {
  opacity: 0;
}

/* Everything inside of .goodmancube is interactive */
.goodmancube {
  width: 100%;
  height: auto;
  padding-bottom: 0 !important;
  cursor: grab;
}

/* Resize the canvas to fit within the window, leaves 100px of text on the bottom of the canvas. */
.goodmancube-wrapper {
  max-width: min(100vw, 100vh - 100px);
  max-height: min(100vw, 100vh - 100px);
}

/* Colour margin, font styling */
.goodmancube-copy {
  margin-top: 50px;
  left: 50%;
  transform: translateX(-50%);
  font-family: 'FoundersGrotesk';
  font-size: 24px;
  font-weight: lighter;

  overflow: visible;
  color: #58B946;
  width: 960px;
  text-align: center;
}

/* Slide up and down animation */
.goodmancube-copy--entering,
.goodmancube-copy--entered {
  transform: translateX(-50%) translateY(0%);
}

.goodmancube-copy--exiting,
.goodmancube-copy--exited {
  transform: translateX(-50%) translateY(50px);
}

/* Responsiveness breakpoints */
@media only screen and (max-width: 1000px) {
  .goodmancube-copy {
    font-size: 20px;
    width: 650px;
  }
}

@media only screen and (max-width: 750px) {
  .goodmancube-copy {
    font-size: 20px;
    width: 550px;
  }
}

@media only screen and (max-width: 600px) {
  .goodmancube-copy {
    margin-top: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 100vw;
    box-sizing: border-box;
    padding: 0 30px;
    font-size: 18px;
    text-align: center;
  }
}





/** Page Styles **/

body {
  background-color: #140C39;
  padding: 0;
  margin: 0;
  font-family: 'FoundersGrotesk', 'Helvetica', Arial, sans-serif;
}
header {
  width: 100%;
  height: 60px;
  background: #FFFFFF;
  padding: 0 20px;
  position: relative;
  z-index: 10;
  text-transform: uppercase;
  text-align: right;
  box-sizing: border-box;
  font-weight: 500;
  font-family: 'FoundersGroteskRegular', 'Helvetica', Arial, sans-serif;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media only screen and (min-width: 768px) {
  header br {
    display: none;
  }
}
header svg {
  width: 70px;
  height: auto;
  position: absolute;
  left: 20px;
  top: 20px;
}
.links {
  width: 75%;
  top: 28px;
  text-align: center;
  position: relative;
  z-index: 10;
  display: block;
  text-transform: uppercase;
  margin: 0 auto;
}
@media only screen and (min-width: 768px) {
  .links {
    top: 20px;
    width: auto;
    left: 50%;
    transform: translateX(-50%);
    display: inline-flex;
    flex-wrap: wrap;
    justify-content: center;
    text-decoration: none;
    height: 25px;
    align-items: center;
  }
}
.links-link {
  width: 50%;
  color: #FFFFFF;
  height: 50px;
  line-height: 50px;
  display: block;
  float: left;
}
.links-link.active {
  color: #00b831;
}
@media only screen and (max-width: 767px) {
  .links-link:after {
    content: "";
    display: block;
    width: 70px;
    height: 1px;
    background: #3218af;
    margin: 0 auto;
  }
  .links-link:last-child:after {
    display: none;
  }
  .links-link:nth-last-child(2):after {
    display: none;
  }
}
@media only screen and (min-width: 768px) {
  .links-link {
    width: auto;
    float: none;
    margin-right: 20px;
    padding-right: 20px;
    text-decoration: none;
    height: 25px;
    line-height: 25px;
    border-right: solid 1px #3218af;
  }
}
.links-link:last-child {
  margin-right: 0;
  padding-right: 0;
  border-right: none;
}
.links-link:hover {
  cursor: pointer;
}
.instructions {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  color: #FFFFFF;
  text-transform: uppercase;
  position: relative;
  font-size: 12px;
  top: 40px;
  clear: both;
}
.instructions img {
  width: 20px;
  height: auto;
  margin-right: 10px;
}



.country {
  position: absolute;
  top: 70px;
  right: 20px;
  font-size: 14px;
  display: flex;
}
.country .link {
  margin-left: 10px;
}
.country .link a {
  color: #FFFFFF;
  text-decoration: none;
  text-transform: uppercase;
}
.country .link a:hover,
.country .link a:focus,
.country .link a.active {
  color: #58B946;
  text-decoration: underline;
}


.iframe header,
.iframe .country {
  display: none;
}

.cn .goodmancube-copy {
  font-family: 'Noto Sans SC', sans-serif;
}

.jp .goodmancube-copy {
  font-family: 'Noto Sans JP', sans-serif;
}